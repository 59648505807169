import React from "react";
import { Box, Grid, Hidden, Typography } from "@mui/material";
import PropTypes from "prop-types";

const useStyles = {
  loginLayoutBox: {
    background: " #f4f5f7",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "100vh",
    height: "auto",
    width: "100%",
    overflow: "hidden",

    "& .loginLayoutContainer": {
      height: "100%",
      minHeight: "625px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },

    "& .leftGridLoginLayout": {},

    "& .rightGridLoginLayout": {
      background: " #f4f5f7",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },

    "& .loginContentLayoutBox": {
      height: "100%",
      width: "100%",
      maxWidth: "1100px",
      minHeight: "625px",
    },
    "& .childrenBox": {
      maxWidth: "440px",
      width: "100%",
    },
    "& .image": {
      width: "32px",
      height: "32px",
    },
  },
};

export default function LoginLayout({ children }) {
  const classes = useStyles;

  return (
    <Box sx={classes.loginLayoutBox}>
      <Box className="loginContentLayoutBox displayCenter">
        <Grid container className="loginLayoutContainer">
          <Grid item xs={12} className="rightGridLoginLayout">
            <Box display={"flex"} gap={"10px"} alignItems={"center"}>
              <img src="/images/logo.png" alt="logo" className="image" />
              <Typography variant="h5" fontWeight={700}>
                Codestrup Infotech
              </Typography>
            </Box>
            <Box className="childrenBox" mt={2}>
              {children}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.node,
};
