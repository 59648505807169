import React from "react";
import { styled, Backdrop } from "@mui/material";
import Loader from "react-js-loader";
const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: "#fff",
}));

const FullScreenLoading = ({ open }) => {
  return (
    <StyledBackdrop open={open}>
      <Loader
        type="bubble-spin"
        bgColor={"rgb(233,64,87)"}
        color="rgb(233,64,87)"
        title={"Loading..."}
      />
    </StyledBackdrop>
  );
};

export default FullScreenLoading;
