import React, { lazy } from "react";
import HomeLayout from "../src/layouts/HomeLayout";
import LoginLayout from "../src/layouts/LoginLayout";
import DashboardLayout from "./layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/auth/login",
    layout: LoginLayout,
    component: lazy(() => import("../src/views/pages/Auth/Login/Login")),
  },
  {
    exact: true,
    path: "/auth/update-password",
    layout: LoginLayout,
    component: lazy(() => import("../src/views/pages/Auth/UpdatePassword/UpdatePassword")),
  },
  {
    exact: true,
    path: "/home",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("../src/views/pages/Dashboard/Dashboard")),
  },
  {
    exact: true,
    path: "/projects",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("../src/views/pages/Projects/Project")),
  },
  {
    exact: true,
    path: "/project/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("../src/views/pages/Projects/ViewProject")),
  },
  {
    exact: true,
    path: "/tasks",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("../src/views/pages/Task/Task")),
  },
  {
    exact: true,
    path: "/task/:id",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("../src/views/pages/Task/ViewTask")),
  },
  {
    exact: true,
    path: "/learning-center",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("../src/views/pages/LearningCenter/LearningCenter")
    ),
  },
  {
    exact: true,
    path: "/achievements",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("../src/views/pages/Achievements/Achievements")
    ),
  },
  {
    exact: true,
    path: "/certificate",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Certificate/Certificate")),
  },
  {
    exact: true,
    path: "/offer-letter",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/OfferLetter/OfferLetter")),
  },
  {
    exact: true,
    path: "/ask-me",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/AskMe/AskMe")),
  },
  {
    exact: true,
    path: "/opportunity",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Jobs/Jobs")),
  },
  {
    exact: true,
    path: "/theory-courses",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/LearningCenter/TheoryCourse")),
  },
  {
    exact: true,
    path: "/course",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/LearningCenter/Course")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("../src/views/errors/NotFound")),
  },
];
