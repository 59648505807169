const url = "https://api.codestrup.in";
// const url = "http://192.168.1.132:8080";

const ApiConfig = {
  login: `${url}/users/login`,
  updatePassword: `${url}/users/updatePassword`,
  applicationData: `${url}/users/applicationData`,
  getAchievement: `${url}/users/getAchievementsData`,
  getAchievement: `${url}/users/getAchievementsData`,
  submitTask: `${url}/users/submitTask`,
  taskStatus: `${url}/users/showUserTask`,
  getLastSubmittedTask: `${url}/users/getLastSubmittedTask`,
  getGemResult:`${url}/gemini/getGemResult`,
  getLinkJobs:`${url}/getLinkJobs`,
  courses:`${url}/api/courses`,
  generate:`${url}/api/generate`,
  image:`${url}/api/image`,
  update:`${url}/api/update`,
};
export default ApiConfig;
