import ApiConfig from "../config/APICongig";
import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./Auth";

export const UserContext = createContext();

export default function AuthProvider(props) {
  const auth = useContext(AuthContext)
  const [applicationData, setApplicationData] = useState({});
  const [loader, setLoader] = useState(false);
  const [achievementData, setAchievementData] = useState(null);
  const userData = async () => {
    setLoader(true);
    try {
      const res = await axios({
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        url: `${ApiConfig.applicationData}`,
      });
      if (res.status === 200) {
        setApplicationData(res.data);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const getAchievement = async () => {
    try {
      const res = await axios({
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        url: ApiConfig.getAchievement,
      });
      if (res.status === 200) {
        setAchievementData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (auth.userLoggedIn) {
      userData();
      getAchievement();
    }
  }, [auth.userLoggedIn]);
  let data = {
    applicationData: applicationData,
    loading: loader,
    achievementData: achievementData,
    userData: ()=> userData(),
    getAchievement: ()=> getAchievement(),
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
