import { MdOutlineDashboard } from "react-icons/md";
import { GoProjectRoadmap } from "react-icons/go";
import { BiTask } from "react-icons/bi";
import { GiArchiveResearch } from "react-icons/gi";
import { GrAchievement } from "react-icons/gr";
import { PiCertificateLight } from "react-icons/pi";
import { BsEnvelopePaper } from "react-icons/bs";
import { MdOutlineQuestionAnswer } from "react-icons/md";
import { MdWorkOutline } from "react-icons/md";


export const sections = [
  {
    items: [
      {
        title: "Home",
        modules: "home",
        icon: MdOutlineDashboard,
        href: "/home",
      },
      {
        title: "Project",
        modules: "project",
        icon: GoProjectRoadmap,
        href: "/projects",
      },
      {
        title: "Task",
        modules: "task",
        icon: BiTask,
        href: "/tasks",
      },
      {
        title: "Learning Center",
        modules: "learning",
        icon: GiArchiveResearch,
        subRoutes:[
          {
            title: "Theory Course",
            modules: "learning",
            icon: GiArchiveResearch,
            href:'/theory-courses'
          }
        ]
      },
      {
        title: "Achievements",
        modules: "Achievements",
        icon: GrAchievement,
        href: "/achievements",
      },
      {
        title: "Ask Me",
        modules: "Ask Me",
        icon: MdOutlineQuestionAnswer,
        href: "/ask-me",
      },
      {
        title: "Jobs",
        modules: "opportunity",
        icon: MdWorkOutline,
        href: "/opportunity",
      },
      {
        title: "Certificate",
        modules: "Certificate",
        icon: PiCertificateLight,
        href: "/certificate",
      },
      {
        title: "View Offer Letter",
        modules: "offerLetter",
        icon: BsEnvelopePaper,
        href: "/offer-letter",
      },
    ],
  },
];
