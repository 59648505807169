import React, { useContext, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SettingsContext from "../src/context/SettingsContext";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import AppRouter from "./AppRouter";
import { createCustomeTheme } from "../src/theme";
import { Toaster } from "react-hot-toast";

export default function App() {
  const themeSeeting = useContext(SettingsContext);
  const theme = createCustomeTheme({
    theme: createTheme(themeSeeting.settings.theme),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Toaster position="top-right" />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}
