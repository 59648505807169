
export const typography = {
  h1: {
    fontSize: '40px',
    fontWeight: 300,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: '32px',
    fontWeight: 300,
    lineHeight: 1.3,
  },
  h3: {
    fontSize: '28px',
    fontWeight: 400,
    lineHeight: 1.3,
  },
  h4: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: 1.35,
  },
  h5: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: 1.4,
  },
  h6: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: 1.5,
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.43,
  },
};

